@import "src/styles/fonts";

// colors
$clr-inactive-tab-border: #d8d8d8;
$clr-inactive-tab-text: rgba(0, 0, 0, 0.4);
$clr-active-tab-text: #39393a;

// feature colors
$clr-active-tab: #ea1b0a;

/// tab bar general
/// ====================================================================================================================
div.tab-bar {
    display: flex;
    flex-direction: row;
}

div.tab-bar > div {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 8px;

    margin-top: 30px;

    border-bottom: 5px solid $clr-inactive-tab-border;
    color: $clr-inactive-tab-text;

    cursor: pointer;

    @include eon-bold;
}

div.tab-bar > div > i {
    background-color: $clr-inactive-tab-text;
}

div.tab-bar > div.active {
    border-bottom: 5px solid $clr-active-tab;
    color: $clr-active-tab-text;
}

div.tab-bar > div.active > i {
    background-color: $clr-active-tab-text;
}

div.proxy-tab {
    flex: 1;
    cursor: unset !important;
}

div.spacer {
    width: 20px;
}
