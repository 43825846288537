@import "../fonts";
@import "../layout";

$link-height: 30px;
$link-icon-size: 20px;
$link-red: #ea1b0a;
$link-red-hover: #d51607;
$link-padding: 8px;

$link-margin-left: calc(#{$link-icon-size} + #{$link-padding});
$link-icon-left: calc(-1 * #{$link-margin-left});

a.eon-link-new {
    height: $link-height;
    position: relative;
    @include eon-bold;
    font-size: 18px;
    margin-left: $link-margin-left;

    text-decoration: none;
    color: $link-red;

    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;

    transition: border, color 150ms ease;

    @include row(flex-start, center);
}

a.eon-link-new:hover {
    border-bottom-color: $link-red-hover;
    color: $link-red-hover;

    &:before {
        background-color: $link-red-hover;
    }
}

a.eon-link-new:before {
    display: block;
    position: absolute;
    top: 0;
    left: $link-icon-left;
    width: $link-icon-size;
    height: 100%;

    content: "";

    mask-image: url("~src/assets/img/icons/arrow.svg");
    mask-position: center center;
    mask-size: contain;
    mask-repeat: no-repeat;

    margin-right: 8px;

    transition: background-color 150ms ease;

    background-color: $link-red;
}
