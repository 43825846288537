label.eon-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;

    font-size: 18px;
    @include eon-regular;

    cursor: pointer;
}

input[type="checkbox"] {
    appearance: none;
    width: 32px;
    height: 32px;

    margin-right: 10px;

    mask-image: url("~src/assets/img/graphics/button-shape-outline.svg");
    mask-position: left center;
    mask-repeat: no-repeat;
    mask-size: contain;

    background-color: #8f9192;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background-color: #2b91a3;
    mask-image: url("~src/assets/img/graphics/checked.svg");
}


label.eon-checkbox.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    &input {
        opacity: 1;
    }
}
