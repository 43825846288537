@import "../mixins/eon-scrollbar";

$household-comparison-thumb: #1ea2b1;
$household-comparison-thumb-hover: #1ea2b1;

.scroll-container-vertical {
    overflow-y: auto;
    width: 100%;
}

.scroll-container-horizontal {
    overflow-x: auto;
    width: 100%;
}

.scroll-container-vertical.household-comparison {
    @include eon-scrollbar($household-comparison-thumb, $household-comparison-thumb-hover);
}
