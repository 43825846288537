//
@import '~ngx-toastr/toastr';

// dependencies
@import "styles/colors";
@import "styles/mixins";

// overlays default / custom
@import '@angular/cdk/overlay-prebuilt.css';
@import "styles/overlay";

// layout
@import "styles/spacer";
@import "styles/layout";

// type
@import "styles/fonts";
@import "styles/heading";

// components
@import "styles/connection-quality";
@import "styles/input";
@import "styles/boeppel";
@import "styles/notifications";

@import "styles/components/menu";
@import "styles/components/tiles";
@import "styles/components/details";
@import "styles/components/page";
@import "styles/components/tab-selector";
@import "styles/components/eon-input";
@import "styles/components/icons";
@import "styles/components/tab-bar";
@import "styles/components/scoll-container";

// buttons
@import "styles/components/button-eon";
@import "styles/components/eon-link";
@import "styles/components/eon-icon-only-button";

// checkbox
@import "styles/components/eon-checkbox";

// charts
@import "styles/charts.global";


/**
 * general
 */
.toast-container .ngx-toastr {
    box-shadow: none !important;
}

.pointer {
    cursor: pointer;
}
