/**
 * DEFINITIONS
 */
$accent-yellow: rgb(255, 195, 0);
$red-muted: #b9280a;
$red-banner: #fac6c2;

/**
 * E.ON
 */
$eon-turquoise: #1ea2b1;

.turquoise {
    background: $eon-turquoise !important;
}
