/**
 * layout
 */

.inflate {
    width: 100% !important;
    height: 100% !important;
}

.center-contents {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-contents-v {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-align-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.col-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.splitrow {
    display: flex;
    flex-direction: row;

    > * {
        width: 50%;

        &:nth-child(odd) {
            margin-right: 7.5px;
        }

        &:nth-child(even) {
            margin-left: 7.5px;
        }
    }

}

.scroll-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}


/**
 * GENERAL
 */
.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.grow-space {
    flex-grow: 1 !important;
}

/**
 * MIXINS
 */
@mixin row($justify: null, $align: null, $reverse: null) {
    display: flex;

    @if $reverse {
        flex-direction: row-reverse;
    } @else {
        flex-direction: row;
    }

    @if $justify {
        justify-content: $justify;
    }

    @if $align {
        align-items: $align;
    }
}

@mixin col($justify: null, $align: null , $reverse:null) {
    display: flex;

    @if $reverse {
        flex-direction: column-reverse;
    } @else {
        flex-direction: column;
    }

    @if $justify {
        justify-content: $justify;
    }

    @if $align {
        align-items: $align;
    }
}
