@import "../fonts";

.detail-view {
    @include eon-regular;
}

/// head
section.detail-head {
    background: #f6f6f7;
    padding: 50px;
}

.detail-head > header {
    display: flex;
    flex-direction: row;

    h1 {
        margin: 0;
        flex: 1 0 auto;
        @include eon-black;
        font-size: 40px;
    }

    button {
        border: none;
        background: transparent;
        padding-left: 20px;
    }
}

.detail-head > section {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

/// INFO
section.detail-info {
    padding-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
    background: #f8f7bf;

    display: flex;
    flex-direction: row;
}

.detail-info > .info-icon {
    padding-top: 5px;
    margin-right: 30px;
}

.detail-info > .info-text {
    p {
        @include eon-regular;
        font-size: 18px;
    }
}

/// CONTENT
section.detail-content {
    padding: 30px 50px 50px;
}

