@import "type/brixsans";

/// regular
/// ====================================================================================================================
@mixin eon-regular() {
    font-family: EONBrixSans, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
}

@mixin font-regular() {
    @include eon-regular;
}

.eon-regular {
    @include eon-regular;
}

.font-regular {
    @include eon-regular;
}

/// regular italic
/// ====================================================================================================================
@mixin eon-regular-italic() {
    font-family: EONBrixSans, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
}

@mixin font-regular-italic() {
    @include eon-regular-italic;
}

.eon-regularit {
    @include eon-regular-italic;
}

.font-regular-it {
    @include eon-regular-italic;
}

/// medium
/// ====================================================================================================================
@mixin eon-medium() {
    font-family: EONBrixSans, sans-serif;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

@mixin font-medium() {
    @include eon-medium;
}

.eon-medium {
    @include eon-medium;
}

.font-medium {
    @include eon-medium;
}


/// bold
/// ====================================================================================================================
@mixin eon-bold() {
    font-family: EONBrixSans, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

@mixin font-bold() {
    @include eon-bold;
}

.eon-bold {
    @include eon-bold;
}

.font-bold {
    @include eon-bold;
}

/// bold italic
/// ====================================================================================================================
@mixin eon-bold-italic() {
    font-family: EONBrixSans, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
}

@mixin font-bold-italic() {
    @include eon-bold-italic;
}

.eon-bold-it {
    @include eon-bold-italic;
}

.font-boldit {
    @include eon-bold-italic;
}

/// black
/// ====================================================================================================================
@mixin eon-black() {
    font-family: EONBrixSans, sans-serif;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

@mixin font-black() {
    @include eon-black;
}

.eon-black {
    @include eon-black;
}

.font-black {
    @include eon-black;
}

/// tag overrides
/// ====================================================================================================================
strong {
    @include eon-bold;
}

p.subtext {
    @include eon-regular;
    font-size: 14px;
    margin-left: 60px;
    padding-right: 20px;
}

p.centered {
    text-align: center;
}

p.small {
    @include eon-regular;
    font-size: 14px;
}

p.white {
    @include eon-regular;
    color: white;
}

