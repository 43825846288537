@import "../layout";

$eon-red: #ea1b0a;
$eon-button-hover-red: #b9280a;

$eon-turquoise: #1ea2b1;
$eon-button-hover-turquoise: #2b91a3;

$eon-button-hover-turquoise-inverted: #ddf1f3;

$eon-grey: rgb(57 57 58 / 80%);

$fs-button: 18px;
$button-height: 48px;
$button-height-sm: 32px;

$button-edge-width: 24px;
$button-edge-width-sm: 16px;


@mixin border-edge($direction) {
    @if $direction == 'left' {
        display: block;
        position: absolute;
        top: 0;
        left: -$button-edge-width;
        height: $button-height;
        width: $button-edge-width;

        mask-image: url("~src/assets/img/shapes/eon-button-shapes/left.svg");

        content: " ";

    } @else {
        display: block;
        position: absolute;
        top: 0;
        right: -$button-edge-width;
        height: $button-height;
        width: $button-edge-width;

        mask-image: url("~src/assets/img/shapes/eon-button-shapes/right.svg");

        content: " ";
    }
    mask-repeat: no-repeat;
    mask-size: contain;
}

@mixin hover-bg-transition {
    transition: background-color 200ms ease;
}

@mixin entire-button-hover-effect(
    $primary-color: #{$eon-red},
    $hover-color: #{$eon-button-hover-red},
    $text-color: white,
    $text-color-hover: white
) {
    @include hover-bg-transition;

    background-color: $primary-color;
    color: $text-color;

    user-select: none;

    &:before {
        @include border-edge('left');
        @include hover-bg-transition;
        background-color: $primary-color;
    }
    &:after {
        @include border-edge("right");
        @include hover-bg-transition;
        background-color: $primary-color;
    }
    &:hover {
        background-color: $hover-color !important;
        color: $text-color-hover;

        &:before {
            background-color: $hover-color !important;
        }

        &:after {
            background-color: $hover-color !important;
        }
    }
}

@mixin eon-button {
    position: relative;
    height: $button-height;

    padding-left: $button-edge-width;
    padding-right: $button-edge-width;

    margin: 6px $button-edge-width;

    @include row(center, center);
    display: inline-flex;

    @include eon-bold;
    font-size: $fs-button;
    border: 0;

    cursor: pointer;

    @include entire-button-hover-effect($eon-red, $eon-button-hover-red);
}


// ICON-ONLY BUTTONS
// =====================================================================================================================
@mixin eon-button-icon-only {
    position: relative;
    height: $button-height;

    padding-left: 0;
    padding-right: 0;

    margin: 6px $button-edge-width;
    @include row(center, center);
    display: inline-flex;

    @include eon-bold;
    font-size: $fs-button;
    color: white;
    border: 0;

    cursor: pointer;

    @include entire-button-hover-effect($eon-red, $eon-button-hover-red);
}

button.eon-button-new {
    @include eon-button;
}

button.eon-icon-button {
    @include eon-button-icon-only;

    > i {
        position: absolute;
        z-index: 1;
    }
}


// LINK BUTTONS
// =====================================================================================================================
a.eon-button-new {
    @include eon-button;
    text-decoration: none;
    color: white;
}

a.eon-button-new:visited {
    color: white;
}


// COLORS
// =====================================================================================================================

// turqouise
button.eon-button-new.turquoise {
    @include entire-button-hover-effect($eon-turquoise, $eon-button-hover-turquoise);
}

button.eon-icon-button.turquoise {
    @include entire-button-hover-effect($eon-turquoise, $eon-button-hover-turquoise);
}

// red reversed
button.eon-button-new.reversed {
    @include entire-button-hover-effect($eon-grey, $eon-red);
}

button.eon-icon-button.reversed {
    @include entire-button-hover-effect($eon-grey, $eon-red);
}

// turqouise-reversed
button.eon-button-new.turquoise-inverted {
    @include entire-button-hover-effect(
            white,
            $eon-button-hover-turquoise-inverted,
            $eon-turquoise,
            $eon-button-hover-turquoise
    );
}


// SMALL
// =====================================================================================================================
button.eon-button-new.small {
    height: $button-height-sm;
    padding-left: $button-edge-width-sm;
    padding-right: $button-edge-width-sm;
    margin-left: $button-edge-width-sm;
    margin-right: $button-edge-width-sm;
}

button.eon-button-new.small:before {
    left: -$button-edge-width-sm;
    height: $button-height-sm;
    width: $button-edge-width-sm;
}

button.eon-button-new.small:after {
    right: -$button-edge-width-sm;
    height: $button-height-sm;
    width: $button-edge-width-sm;
}

// SMALL ICON
// ================================================================================
button.eon-icon-button.small {
    height: $button-height-sm;
    margin-left: $button-edge-width-sm;
    margin-right: $button-edge-width-sm;
}

button.eon-icon-button.small:before {
    left: -$button-edge-width-sm;
    height: $button-height-sm;
    width: $button-edge-width-sm;
}

button.eon-icon-button.small:after {
    right: -$button-edge-width-sm;
    height: $button-height-sm;
    width: $button-edge-width-sm;
}
