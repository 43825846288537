@import "../colors";

$icon-size-s: 18px;
$icon-size-s-m: 24px;
$icon-size-m: 32px;
$icon-size-l: 45px;

$icon-sizes: ("s": $icon-size-s, "sm": $icon-size-s-m, "m": $icon-size-m, "l" : $icon-size-l);

@mixin icon($path:null, $size: null, $is-mask: null) {
    display: inline-block;

    @if $size == m {
        width: $icon-size-m !important;
        height: $icon-size-m !important;
    } @else if $size == sm {
        width: $icon-size-s-m !important;
        height: $icon-size-s-m !important;
    } @else if $size == l {
        width: $icon-size-l !important;
        height: $icon-size-l !important;
    } @else {
        width: $icon-size-s !important;
        height: $icon-size-s !important;
    }

    @if ($path) {
        @if ($is-mask == true) {
            mask-image: url($path);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;

            -webkit-mask-image: url($path);
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;

        } @else {
            background-image: url($path);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

i.feedin {
    @include icon('~src/assets/img/icons/sun.webp', m, true);
    background-color: #39393a;
}

i.consumption {
    @include icon('~src/assets/img/icons/thunder.webp', m, true);
    background-color: #1ea2b1;
}

i.consumption-alert {
    @include icon('~src/assets/img/icons/thunder.webp', m, true);
    background-color: #b00402;
}

i.calendar {
    @include icon('~src/assets/img/icons/calendar.webp', s, true);
    background-color: #828282;
}

/**
 * password visibility icon
 */
i.password-hidden {
    @include icon('~src/assets/img/icons/eye.webp', sm, false);
    //background-color: #39393a;
}

i.password-visible {
    @include icon('~src/assets/img/icons/eye-crossed.webp', sm, false);
    //background-color: #39393a;
}

@each $name, $size in $icon-sizes {
    i.info-#{$name} {
        @include icon('~src/assets/img/icons/info.svg', $name, true);
    }

    i.info-filled-#{$name} {
        @include icon('~src/assets/img/icons/info-filled.svg', $name, true);
    }

    i.close-#{$name} {
        @include icon('~src/assets/img/icons/close.webp', $name, true);
    }

    i.alert-#{$name} {
        @include icon('~src/assets/img/icons/alert.svg', $name, true);
    }

    i.plus-#{$name} {
        @include icon('~src/assets/img/icons/plus.svg', $name, true);
    }

    i.minus-#{$name} {
        @include icon('~src/assets/img/icons/minus.svg', $name, true);
    }

    i.wifi-#{$name} {
        @include icon("~src/assets/img/icons/wifi.svg", $name, true);
    }

    i.mail-#{$name} {
        @include icon("~src/assets/img/icons/mail.svg", $name, true);
    }

    i.phone-#{$name} {
        @include icon("~src/assets/img/icons/phone.svg", $name, true);
    }

    i.chevron-up-#{$name} {
        @include icon("~src/assets/img/icons/chevron-up.svg", $name, true);
    }

    i.chevron-down-#{$name} {
        @include icon("~src/assets/img/icons/chevron-down.svg", $name, true);
    }

    i.chevron-left-#{$name} {
        @include icon("~src/assets/img/icons/chevron-left.svg", $name, true);
    }

    i.chevron-right-#{$name} {
        @include icon("~src/assets/img/icons/chevron-right.svg", $name, true);
    }

    i.calendar-#{$name} {
        @include icon("~src/assets/img/icons/calendar.webp", $name, true);
    }

    i.edit-#{$name} {
        @include icon("~src/assets/img/icons/edit.svg", $name, true);
    }

    i.check-#{$name} {
        @include icon("~src/assets/img/icons/check.svg", $name, true);
    }

    i.trash-#{$name} {
        @include icon("~src/assets/img/icons/trash.svg", $name, true);
    }

    i.more-#{$name} {
        @include icon("~src/assets/img/icons/more.svg", $name, true);
    }

    i.filter-#{$name} {
        @include icon("~src/assets/img/icons/filter.svg", $name, true);
        transform: rotate(90deg);
    }

    i.rank-#{$name} {
        @include icon("~src/assets/img/icons/rank@2x.webp", $name, true);
    }

}

i.dark-grey {
    background-color: #39393a;
}

i.turquoise {
    background-color: #1ea2b1;
}

i.bordeaux {
    background-color: #b00402;
}

i.white {
    background-color: #ffffff;
}

i.dark {
    background-color: #39393a;
}
