@font-face {
  font-family: "EONBrixSans";
  font-style: normal;
  font-weight: 900;
  src: url("./EON_BrixSansWeb-Black.woff");
}

@font-face {
  font-family: "EONBrixSans";
  font-style: normal;
  font-weight: 700;
  src: url("./EON_BrixSansWeb-Bold.woff");
}

@font-face {
  font-family: "EONBrixSans";
  font-style: normal;
  font-weight: 500;
  src: url("./EON_BrixSansWeb-Medium.woff");
}

@font-face {
  font-family: "EONBrixSans";
  font-style: normal;
  font-weight: 400;
  src: url("./EON_BrixSansWeb-Regular.woff");
}
